// Dashboard.js
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { Button } from 'antd';
import '../../../common/components/Calender.css';
import searchIcon from '../../../data/Group 536.png';
import LeadUpperTab from "../../../common/components/LeadComponent/UpperTab";
import { LabeledInput } from "../../../common/components/LeadComponent/CommonTextField";
import ActivityLogs from "../LeadScreen/Tabs/ActivityLogTabs";
import FilesTab from "../LeadScreen/Tabs/FileTab";// Import the FilesTab component
import LenderPage from "./InduvidualDealTabs/LenderPage";
import GoodMorningBar from "../../../common/components/GoodmorningBar";
import SecondaryNavigation from "../../../common/components/SecondaryNavigation/SecondaryNavigation";
import AgentDealLender from "./InduvidualDealTabs/AgentDealLender/AgentDealLender";
import OldDealLenderTable from '../SubmissionBoard/OldDealLenderTable';
import ApprovalConfirmModal from './InduvidualDealTabs/ApprovalConfirmModal';
import BackButton from "../SubmissionBoard/backButton";


const IndividualDeal = () => {
  const [selectedTab, setSelectedTab] = useState('Information');
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedButtonType, setSelectedButtonType] = useState('');

  const { role } = useSelector((state) => state.auth);
  const { selectedTab: tabSelected } = useSelector((state) => state.auth);

  const location = useLocation();
  const { deal, fromOldTable } = location.state || {};

  // console.log(" Deal  -----------------  ", deal)

  const handleTabClick = (tab) => {
   
    setSelectedTab(tab);
  };

  const handleConfirmationModal = (type) => {
    setSelectedButtonType(type);
    setShowConfirmationModal(true);
  };

  useEffect(() => {
    // Prevent API calls when navigating back
    if (!deal) {
      // Handle case when deal is not present (e.g., navigate away and back)
    }
    console.log("Deal ----->> " , deal)
  }, [deal]);

  const menuItems = [
    "Dashboard",
    "Leads",
    "Deals",
    "Submission Board",
    "Lender directory",
    "Compliance",
    "Commision portal",
  ];

  const users = [
    {
      'name': 'Olivia Rhye',
      'img': 'https://cdn.theorg.com/5f6cf081-3715-4c11-863c-036782203c9b_thumb.jpg'
    },
    {
      'name': 'Phoenix Baker',
      'img': 'https://img.freepik.com/free-photo/close-up-photo-amicable-man-black-shirt-posing-his-passport-picture_132075-8192.jpg'
    },
    {
      'name': 'Lana Steiner',
      'img': 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTg7k5TDqDaaOl1gvZJLojqjPyVLdevAICDg&s'
    }];

  return (
    <div className="flex h-auto overflow-y-hidden">
      <SecondaryNavigation items={menuItems} />
      <main className={`flex-1 bg-background-color p-6 ${(role === 'operations') && (selectedTab === 'Lenders') ? "h-full min-h-screen" : "h-screen"} flex flex-col`}>
        <GoodMorningBar userName="Manish" />
        <div className="flex justify-between items-center mx-4 mt-4">
          <div className="flex gap-5 h-8 my-5">
              <BackButton/>
            <LeadUpperTab title={'General'} isSelected={selectedTab === 'Information'} onClick={() => handleTabClick('Information')} />
            {((role === 'agents' && tabSelected === 'Deals') || (deal?.status !== "Approved" && tabSelected === 'Deals')) ? '' : <LeadUpperTab title={'Lenders'} isSelected={selectedTab === 'Lenders'} onClick={() => handleTabClick('Lenders')} />}
            {fromOldTable ? null :
              <>
                <LeadUpperTab title={'Activity Logs'} isSelected={selectedTab === 'Activity logs'} onClick={() => handleTabClick('Activity logs')} />
                <LeadUpperTab title={'Files'} isSelected={selectedTab === 'Files'} onClick={() => handleTabClick('Files')} />
                {/* <LeadUpperTab title={'Tag People'} isSelected={selectedTab === 'Tag People'} onClick={() => handleTabClick('Tag People')} /> */}
              </>
            }
          </div>
          <div className="flex gap-2">
            <div className="flex items-center gap-2 bg-white rounded-3xl h-9 w-fit px-2">
              <div className="bg-custom-green flex justify-between items-center rounded-full w-7 h-7">
                <img
                  src={searchIcon}
                  alt="Search Icon"
                  className="w-5 h-5 justify-center m-1"
                />
              </div>
              <div className="focus:outline-none">
                Lead ID <span className="font-bold">{deal?.tag}</span>
              </div>
            </div>

            <div className={`flex items-center space-x-2 px-2 py-1  ${deal?.status === 'Decline' ? 'bg-declined-bgcolor' : deal?.status === "Approved" ? "bg-operation-green" : "bg-pending-background"} text-white rounded-2xl border w-28`}>
              <span className={`h-2 w-2 ${deal?.status === 'Decline' ? 'bg-red-600' : deal?.status === "Approved" ? 'bg-green-500' : 'bg-pendingText'} rounded-full`}></span>
              <span className={` ${deal?.status === 'Decline' ? 'text-red-600' : deal?.status === "Approved" ? "text-approved-text" : "text-pendingText"} font-semibold`}>{deal?.status}</span>
            </div>

          </div>

        </div>


        {selectedTab === 'Information' && (
          <>
            <div className="bg-white rounded-lg py-6 px-7 h-auto">
              <div className="flex justify-between mb-3">
                <div className="text-lg font-semibold text-good-morning-text">
                  Information
                </div>
              </div>
              <div className="space-y-7">
                <div className="flex space-x-4">
                  <LabeledInput label={'Company'} value={deal?.company_name} placeholder={'Enter company name'} />
                  <LabeledInput
                    label={'Agent'}
                    value={fromOldTable ? `${deal?.agent}` : `${deal?.firstName} ${deal?.lastName}`}
                    placeholder={'Enter Agent name'}
                  />
                </div>
                <div className="flex space-x-4">
                  <LabeledInput
                    label={'Date'}
                    value={fromOldTable ? moment(deal?.date).format("MM/DD/YYYY") : moment(deal?.createdAt).format("MM/DD/YYYY")}
                    placeholder={'19/06/2024'}
                  />
                  <LabeledInput label={'Lead ID'} value={deal?.tag ?? ""} placeholder={'23NDJNS'} />
                </div>
              </div>
            </div>
            {(role === 'operations' ||  role ===  'opAdmin') && tabSelected === "Deals" &&
              <div className=" bottom-7 right-14 flex space-x-4 fixed">
                <Button
                  className="border-2 border-custom-green bg-background-color text-good-morning-text px-6 py-2 rounded-lg h-11 font-semibold"
                  onClick={() => handleConfirmationModal("Decline")}
                >
                  Decline
                </Button>
                <Button
                  size='large'
                  onClick={() => handleConfirmationModal("Approve")}
                  className='bg-custom-green text-white rounded-lg h-11 font-semibold cursor-pointer px-6 py-2'
                >
                  Approve
                </Button>
              </div>
            }
          </>
        )}
        {selectedTab === 'Lenders' && (((role === 'operations') && tabSelected === 'Submission Board') || (role === 'agents' && tabSelected === 'Submission Board') ?
          <LenderPage deal={deal} />
          :(role === 'admin' || role === 'opAdmin') ? (fromOldTable)?<OldDealLenderTable user_id={deal?.user_id}/> : <LenderPage deal={deal}/> : <AgentDealLender/>
          // : (role === 'admin') ? <LenderPage deal={deal}/> : <AgentDealLender />
          // : (role === 'admin' && fromOldTable) ? <OldDealLenderTable user_id={deal?.user_id} /> : <AgentDealLender />
          // : (role === 'admin' && fromOldTable) ? <OldDealLenderTable user_id={deal?.user_id} /> : <LenderPage deal={deal} />
        )}
        {selectedTab === 'Activity logs' && (
          <div className="flex space-x-4 h-screen">
            <div className="flex-grow">
              <ActivityLogs id={deal?._id} leadTag={deal?.tag} />
            </div>
          </div>
        )}
        {selectedTab === 'Files' && <FilesTab deal={deal} />}
        {/* {selectedTab === 'Tag People' && (
          <div className=" rounded-2xl p-4 space-y-4 h-screen">
            <div className="flex items-center bg-white rounded-3xl pl-1">
              <img src={searchIcon} alt="Search People" className="w-8 h-8 mr-2" />
              <input type="text" placeholder="Add people" className="bg-white focus:outline-none h-10 pl-3 flex-grow" />
            </div>
            <div className="space-y-4">
              {users.map((user, index) => (
                <div key={index} className="flex items-center bg-white rounded-lg p-2 shadow">
                  <div className="text-good-morning-text font-semibold">
                    {user.name}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )} */}
        {showConfirmationModal &&
          <ApprovalConfirmModal
            deal={deal}
            showConfirmationModal={showConfirmationModal}
            selectedButtonType={selectedButtonType}
            onCancel={() => setShowConfirmationModal(false)}
          />
        }
      </main >
    </div >
  );
};

export default IndividualDeal;
